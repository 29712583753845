.sticky-navbar {
  background: #fff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  padding-left:0.4% !important;
  padding-right:0.4% !important;
  width: 100% !important;
  overflow-y: auto !important;
  overflow: visible !important;
}
/* Add this CSS to your styles */


/* Adjust the z-index to make sure the dropdown is above other elements */
.navbar-nav .nav-link {
  z-index: 1;
}
.dropdown-menu{
  border-radius: 9px !important;
  border: 1.486px solid #EBEBEB !important;
  background: #FFF !important;
  box-shadow: 0px 2.97137px 37.1421px 0px rgba(0, 0, 0, 0.05) !important;
  margin-left: 0rem !important;
}
.dropdown-item:hover{
  background-color: #FFF3EF !important;
}

.navlogo{
  margin-left:-0.5rem !important;
} 
a{
  text-decoration: none !important;
}
.nav-link{
  font-size:0.9rem !important;
  z-index: 1;
}
.buttonbody{
  margin-top:1rem;
  margin-bottom:1rem;
  margin-left:0rem;
  display: flex;
  gap:0.9rem
}
.button:hover{
  background-color: #F14810 !important;
  color:#fff !important;  

}
.offcanvas{
  width:100% !important;
  background: url(../../../assets/offcanvasbg.png);
  background-size: cover;
  background-repeat: no-repeat;
}


.cardLink{
  border-radius: 8px;
border: 1px solid #F14810;
box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.06);
display: flex;
height: auto;
padding-top:1.5rem;
padding-bottom:1.5rem;
padding-left:1rem;
padding-right:1rem;
gap: 173px;
}
.nav-link{
  font-size:0.8rem !important;
  font-family: 'Jost', sans-serif;
  color: #313232 !important;
  text-decoration: none !important;
  margin-left:0rem;
  margin-right:0rem;
  z-index: 1;
  
}

.selectlang{
  background-color:#F1F1F1 !important;
  margin-left:0rem;
  margin-top:0rem;
  padding-left:0.3rem;
  padding-right:0.7rem;
  padding-top:0.6rem;
  padding-bottom:0.7rem;
  margin-bottom:0.8rem;
  border-radius:0.4rem;
  width: 5rem;
  cursor: pointer;
}
.btn-close{
  --bs-btn-close-bg:url(../../../assets/cancel.svg) !important;
  background-color: #9e9e9e !important;
  width:0.6rem !important;
  height:0.6rem !important;
 
}
.selectionarea{
  background: url(../../../assets/mapbg.svg);
  background-size: cover;
  margin-top: 0.9rem;
}
.noticecard{
  background-color: #FFF3EF;
  padding: 0.5rem;
  padding-bottom:0rem;
  display: flex;
  flex-direction:row;
  gap: 7px;
  border-radius:0.5rem;
}
.countrySelection-active{
  padding:0.6rem;
  background-color:#FFF6F3;
  border-radius: 0.5rem;
  display:flex;
  align-items:center;
  align-content:center;
  border:0.5px;
  border-style:solid;
  border-color:#F14810;
}

.countrySelection {
  padding:0.6rem;
  background-color:none;
  border-radius: 0.5rem;
  display:flex;
  align-items:center;
  align-content:center;
}
.countryModal{
  left:0% !important;
  top: 6% !important;
  width: 100% !important;
}






@media (min-width: 576px) { 
  .offcanvas{
    width:45% !important;
  }
  

 }


@media (min-width: 768px) { 
  .sticky-navbar{
    padding-left:7%;
    padding-right:7%;
  }
  .navlogo{
    margin-left:0rem !important;
  }
  .buttonbody{
    margin-top:0.6rem;
    margin-bottom:0.6rem;
    margin-left:0rem;
  }
  .offcanvas{
    width:40% !important;
  }
  .dropdown-menu{
    border-radius: 9px !important;
    border: 1.486px solid #EBEBEB !important;
    background: #FFF !important;
    box-shadow: 0px 2.97137px 37.1421px 0px rgba(0, 0, 0, 0.05) !important;
    margin-left: 0rem !important;
  }
  .nav-link{
    font-size:0.8rem !important;
    font-family: 'Jost', sans-serif;
    color: #313232 !important;
    text-decoration: none !important;
    margin-left:0.3rem;
    margin-right:0.3rem;
    z-index: 1;
    
  }
  .selectlang{
    background-color:#F1F1F1 !important;
    margin-left:0rem;
    margin-top:0.8rem;
    padding-left:0.5rem;
    padding-right:0.5rem;
    padding-top:0.3rem;
    padding-bottom:0.8rem;
    margin-bottom:1rem;
    border-radius:0.4rem;
    width: 5rem;
  }
  .countryModal{
    left: 50% !important;
    top: 6% !important;
    width: 45% !important;
  }
  
  

 }


@media (min-width: 992px) { 
  .sticky-navbar{
    padding-left:5% !important;
    padding-right:5% !important;
   
  }
  .buttonbody{
    margin-top:0rem;
    margin-bottom:0rem;
    margin-left:1.2rem;
  }
  .offcanvas{
    width:29% !important;
  }
  .selectlang{
    background-color:#F1F1F1 !important;
    margin-left:0.8rem;
    margin-top:0rem;
    padding-left:0.5rem;
    padding-right:0.5rem;
    padding-top:0.3rem;
    padding-bottom:0rem;
    margin-bottom:0rem;
    border-radius:0.4rem;
    width: auto;
  }
  .countryModal{
    left: 62.0% !important;
    top: 6% !important;
    width: 29% !important;
  }
  .dropdown-menu{
    border-radius: 9px !important;
    border: 1.486px solid #EBEBEB !important;
    background: #FFF !important;
    box-shadow: 0px 2.97137px 37.1421px 0px rgba(0, 0, 0, 0.05) !important;
    margin-left: -2rem !important;
  }
  
  


 }


@media (min-width: 1200px) { 
  .sticky-navbar{
    padding-left:6% !important;
    padding-right:6% !important;
  }
  .countryModal{
    left: 65.0% !important;
    top: 6% !important;
    width: 26% !important;
  }
  .countryModal{
    left: 68.0% !important;
    top: 6% !important;
    width: 25% !important;
  }

 }


@media (min-width: 1400px) { 
  .sticky-navbar{
    padding-left:14% !important;
    padding-right:14% !important;
  }

 }
  
 @media (min-width: 1600px) { 
  .sticky-navbar{
    padding-left:20% !important;
    padding-right:20% !important;
  }

 }