@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Redressed&display=swap");


btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #F14810 !important;
  --bs-btn-border-color: #F14810 !important;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #F14810 !important;
  --bs-btn-hover-border-color: #F14810 !important;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #F14810 !important;
  --bs-btn-active-border-color: #F14810 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #F14810 !important;
  --bs-btn-disabled-border-color: #F14810 !important;
}
.btn {
  border: 1px solid #F14810 !important;
}

/* counter section */

.counter {
  background: #e8420b url(../../../assets/Counterbg.svg);
  width: 100%;
  height: auto;
  padding-left: 9% !important;
  padding-right: 9% !important;
}
.counterRow {
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  padding-top: 2.2rem;
  padding-bottom: 2rem;
}
.countervalue {
  font-size: 2.5rem;
  color: white;
  font-family: "Jost", sans-serif;
}
.counterparagraph {
  display: flex;
  color: white;
  font-family: "Jost", sans-serif;
  font-size: 0.8rem;

  justify-content: left;
}
.image-container {
  position: relative;
  padding-top:2rem;
}
.accbal {
  position: absolute;
  left: -5%;
  top: -16%;
  display: none;
}
.graph {
  position: absolute;
  left: 60%;
  top: 82%;
  display: none;
}

/* about adept */
.aboutadept {
  padding-left: 3% !important;
  padding-right: 3% !important;
  padding-top: 3rem;
  margin-top: -1rem;
  margin-bottom:4.5rem;
}
.aboutadeptright p {
  font-size: 0.82rem;
  line-height: 190.5%;
}
.aboutadept h2 {
  color: #f14810;
  font-size: 2.5rem;
  font-family: "Jost", sans-serif;
  font-weight: bold;
}
.aboutadeptimagecontainer {
  position: relative;
}

.institutionimage {
  position: absolute;
  left: 25%;
  top: 60%;
  display: none;
}
.organisationimage {
  position: absolute;
  left: 18%;
  top: 89%;
  display: none;
}
.paymentimage {
  position: absolute;
  left: 55%;
  top: 77%;
  display: none;
}
.flowimage {
  width: 100% !important;
}

.services{
  margin-top: 1rem;
  padding-top: 3rem;
  padding-bottom: 4rem;
  background-color: #fff8f5;
}
.servicesrow{
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1rem;
  padding-bottom: 1.2rem;
}

/* howitworks */

.howitwork {
  margin-top: 4rem;
  padding-top: 3rem;
  padding-bottom: 4rem;
  background-color: #fff8f5;
}
.howitworkrow {
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1rem;
  padding-bottom: 1.2rem;
}
.howitworkhead {
  color: #515151;
  font-size: 2.5rem;
  font-family: "Jost", sans-serif;
  font-weight: bold;
}

.howitworkparagraph {
  color: #5c5571;
  font-size: 0.9rem;
  font-family: "Jost", sans-serif;
  width: 100%;
}

.howitworkcardbody {
  background: #fcfcfc;
  border-top: 2px solid #f14810;
  box-shadow: 8px 6px 8px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2rem;
  width: 100%;
}
.howitworkeachparagraph {
  font-size: 0.8rem;
  font-family: "Jost", sans-serif;
  color: #5c5571;
}

/* circulardiv */
.circleradiusdiv {
  border-radius: 29px;
  background: #eaeaea;
  display: flex;
  padding: 5px 29px 5px 29px;
  justify-content: center;
  align-items: center;
  width: 6.2rem;
  margin-bottom: 0.5rem;
  font-size: 0.86rem;
  font-family: "Jost", sans-serif;
  color: #f14810;
  font-weight: 600;
}

.circleradiusdivx1 {
  border-radius: 29px;
  background: #EAEAEA;
  display: flex;
  padding: 5px 29px 5px 29px;
  justify-content: center;
  align-items: center;
  width: 6.2rem;
  margin-bottom: 0.5rem;
  font-size: 0.86rem;
  font-family: "Jost", sans-serif;
  color: #f14810;
  font-weight: 600;
}

/* accordion */
.accordion{
  --bs-accordion-btn-focus-box-shadow: none !important;
  --bs-accordion-btn-active-icon:url(../../../assets/nonactiveaccordion.svg) !important;
  --bs-accordion-btn-icon:url(../../../assets/nonactiveaccordion.svg) !important;
  --bs-accordion-btn-icon-transform:rotate(180deg) !important;
  --bs-accordion-btn-icon-width: 1.1rem !important;
}
.accordion-btn-icon{
  margin-top:0.5rem !important;
}

.accordion-button {
  border-radius: 20px !important;
  border: 2px solid #f14810 !important;
  background: #fff8f5 !important;
  font-size:0.86rem !important;
  font-weight: 500 !important;
  color: #5E5E5E !important;
  font-family: "Jost", sans-serif;

}
.accordion-body {
  color: #4C4B4B !important; 
  font-size:0.8rem !important;
  line-height: 205.5%; /* 36.99px */
  font-family: "Jost", sans-serif;
}

.accordion-button:focus{
  border-radius: 20px !important;
  border: 2px solid #f14810 !important;
  background: #fff8f5 !important;
}
.accordion-button:not(.collapsed){
  border-radius: 20px !important;
  border: 2px solid #f14810 !important;
  background: #fff8f5 !important;
  color: #5E5E5E !important;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 10px !important;

}
.accordion-item{
  margin-bottom:1rem;
}

/* frequently asked question */
.FAQ {
  padding-left: 3% !important;
  padding-right: 3% !important;
  padding-top: 2rem;
  margin-top: 1.5rem;
}
.mainaccordion{
  width:100%;
}
/* circular card */
.circular-card {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 2px solid #ffffff;
  position: relative;
}

.circular-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}

.circular-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* This property makes the image cover the circular div */
}


/* stack of card */

.card-stack {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.card-stack > div {
  width: 3.4rem;
  height: 3.4rem;
  border-radius: 50%;
  border: 2px solid #ffffff;
  overflow: hidden;
  position: absolute;
}

.card-stack > div:first-child {
  z-index: 3;
  transform: translateX(-50%);
  left: 15%;
}

.card-stack > div:nth-child(2) {
  z-index: 2;
  transform: translateX(-100%);
  left: 13%;
}

.card-stack > div:last-child {
  z-index: 1;
  transform: translateX(0);
  left: 17%;
}

.middle-card {
  position: relative;
  width: 4.5rem; /* Adjust the size of the middle card as needed */
  height: 4.5rem;
  border-radius: 50%;
  border: 1px solid #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a shadow effect to the middle card */
}
.Contactus{
  margin-top:2.5rem;
  margin-bottom:2.5rem;
  background-color: #F9FAFB;
  width:100%;
  border-radius:0.5rem;
  
  
}
.image-container #imagex {
  filter: grayscale(100%);
  transition: filter 0.2s ease-in-out;
}

/* Hover state, grayscale effect */
.image-container #imagex:hover {
  filter: grayscale(0%);
}

.carousel-container {
  position: relative;
  margin-bottom:4rem;
}



.carousel-control {
  position: absolute;
  bottom: 50px; 
  top:90%;
}


.carousel-control-next-icon {
  background-image: url(../../../assets/carouselrighticon.svg) !important;
  width:3rem !important;
  height:3rem !important;
}

.carousel-control-prev-icon {
  background-image: url(../../../assets/carousellefticon.svg) !important;
  width:3rem !important;
  height:3rem !important;
}
.partner{
  padding-left: 3% !important;
  padding-right: 3% !important;
  padding-top: 3rem;
  margin-top: 1.5rem;
}
.partner p{
  width:100%;
}
.footer{
  background-color: #263238;
  padding-top:3rem;
  padding-bottom:2rem;
  padding-left:3% !important;
  padding-right:3% !important;
}
.footer ul li{
  color:#ffffff;
}

.servicecontainer{
  margin-top:1rem !important;
}
.linkcontainer{
  margin-top:1rem !important;
}
.contactus{
  margin-top:0.5rem !important;
}
.all-image{
  margin-top:2.5rem;
}
a{
  color:#263238 !important;
}
a:active{
  color: #f14810 !important;
}
.nav-link a{
  color:#263238 !important;
}
.nav-link a:active{
  color: #f14810 !important;
}
.arrow{
  margin-left:4.2rem;
}
.btn-close{

  color: #ffffff !important;
  border-radius:100% !important;
  --bs-btn-close-bg:url(../../../assets/closeicon.svg) !important;
  --bs-btn-close-focus-shadow:none !important;
}
.requestcall{
  background-image: url(../../../assets/requestcall.png);
  background-size: cover;
  padding:3rem;
  padding-top:3rem !important;
  padding-bottom:3rem !important;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}
.contactsection{
  padding-top:3rem !important;
  padding-bottom:3rem !important;
  padding:3rem !important;
 
}

.requestcall .form-control{
  border-radius: 0px 5px 5px 0px!important;
  border: 1px solid #868585 !important;
  background: rgba(80, 78, 78, 0.90) !important;
  backdrop-filter: blur(4px) !important;
  color:#FFF !important;
  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.02) !important;
 
}
.input-group-text{
  background: rgba(80, 78, 78, 0.90) !important;
  backdrop-filter: blur(4px) !important;
  border: 1px solid #868585 !important;
}
#inlineFormInputGroup::-webkit-input-placeholder {
  color: #FFF !important;
  font-size:0.75rem !important;
}

#inlineFormInputGroup:-moz-placeholder {
  color: #FFF !important;
  font-size:0.75rem !important;
}

#inlineFormInputGroup::-moz-placeholder {
  color: #FFF !important;
  font-size:0.75rem !important;
}

#inlineFormInputGroup:-ms-input-placeholder {
  color: #FFF !important;
  font-size:0.75rem !important;
}








@media (min-width: 576px) {
}

@media (min-width: 768px) {
 
  .accbal {
    position: absolute;
    left: -13%;
    top: 12%;
    display: none;
  }
  .graph {
    position: absolute;
    left: 52%;
    top: 80%;
    display: none;
  }

  /* about adept */
  .aboutadept {
    padding-left: 7% !important;
    padding-right: 7% !important;
    padding-top: 2rem;
  }
  .aboutadeptright p {
    font-size: 0.82rem;
    line-height: 190.5%;
  }
  .aboutadeptimagecontainer {
    position: relative;
  }

  .institutionimage {
    position: absolute;
    left: 30%;
    top: 60%;
    display: inline;
  }
  .organisationimage {
    position: absolute;
    left: 23%;
    top: 89%;
    display: inline;
  }
  .paymentimage {
    position: absolute;
    left: 60%;
    top: 77%;
    display: inline;
  }
  .flowimage {
    width: 355px !important;
  }

  .services{
    margin-top: -0.5rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    background-color: #fff8f5;
  }
  .servicesrow{
    padding-left: 4.5%;
    padding-right: 4.5%;
    padding-top: 1rem;
    padding-bottom: 3.5rem;
  }

  /* howitworks */

  .howitwork {
    margin-top: 5rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    background-color: #fff8f5;
  }
  .howitworkrow {
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
  }
  .howitworkhead {
    color: #515151;
    font-size: 2.5rem;
    font-family: "Jost", sans-serif;
    font-weight: bold;
  }

  .howitworkparagraph {
    color: #5c5571;
    font-size: 0.85rem;
    font-family: "Jost", sans-serif;
    width: 58%;
  }

  .howitworkcardbody {
    background: #fcfcfc;
    border-top: 2px solid #f14810;
    box-shadow: 10px 8px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
    width: 100%;
  }
  .howitworkeachparagraph {
    font-size: 0.8rem;
    font-family: "Jost", sans-serif;
    color: #5c5571;
  }
  /* frequently asked question */
.FAQ {
  padding-left: 4% !important;
  padding-right: 4% !important;
  padding-top: 2rem;
  margin-top: 1.5rem;
}
.mainaccordion{
  width:100%;
}
.Contactus{
  margin-top:3.5rem;
  margin-bottom:2.5rem;
  background-color: #F9FAFB;
  width:95% !important;
  border-radius:0.5rem;
}
.partner{
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-top: 3rem;
  margin-top: 1.5rem;
}
.partner p{
  width:70%;
}
.footer{
  background-color: #263238;
  padding-top:3rem;
  padding-bottom:2rem;
  padding-left:5% !important;
  padding-right:5% !important;
}
.servicecontainer{
  margin-top:0rem !important;
}
.linkcontainer{
  margin-top:0rem !important;
}
.contactus{
  margin-top:0rem !important;
}
.all-image{
  margin-top:0rem;
}
.arrow{
  margin-left:2rem;
}
.counter {
  background: #e8420b url(../../../assets/Counterbg.svg);
  width: 100%;
  height: auto;
  padding-left: 9% !important;
  padding-right: 9% !important;
}
.requestcall{
  background-image: url(../../../assets/requestcall.png);
  background-size: cover;
  padding:3.0rem !important;
  padding-top:3rem !important;
  padding-bottom:3rem !important;
  border-radius: 0rem 0.5rem 0.5rem 0rem;
}
.contactsection{
  padding-top:3rem !important;
  padding-bottom:3rem !important;
  padding:3rem !important;
 
}



}







@media (min-width: 820px) {
  .accbal {
    position: absolute;
    left: -13%;
    top: 12%;
    display: inline;
  }
  .graph {
    position: absolute;
    left: 52%;
    top: 80%;
    display: inline;
  }
  .Contactus{
    margin-top:3.5rem;
    margin-bottom:2.5rem;
    background-color: #F9FAFB;
    width:85% !important;
    border-radius:0.5rem;
  }
}





@media (min-width: 992px) {
 
  .accbal {
    position: absolute;
    left: -17%;
    top: 12%;
  }
  .graph {
    position: absolute;
    left: 60%;
    top: 80%;
  }

  /* about adept */
  .aboutadept {
    padding-left: 7% !important;
    padding-right: 7% !important;
    padding-top: 2rem;
  }
  .aboutadeptright p {
    font-size: 0.82rem;
    line-height: 190.5%;
  }
  .aboutadeptimagecontainer {
    position: relative;
  }

  .institutionimage {
    position: absolute;
    left: 24%;
    top: 60%;
  }
  .organisationimage {
    position: absolute;
    left: 22%;
    top: 89%;
  }
  .paymentimage {
    position: absolute;
    left: 60%;
    top: 77%;
  }

  /* howitworks */

  .howitwork {
    margin-top: 7rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    background-color: #fff8f5;
  }
  .howitworkrow {
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
  }
  .howitworkhead {
    color: #515151;
    font-size: 2.5rem;
    font-family: "Jost", sans-serif;
    font-weight: bold;
  }

  .howitworkparagraph {
    color: #5c5571;
    font-size: 0.85rem;
    font-family: "Jost", sans-serif;
    width: 58%;
  }

  .howitworkcardbody {
    background: #fcfcfc;
    border-top: 2px solid #f14810;
    box-shadow: 10px 8px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
    width: 100%;
  }
  .howitworkeachparagraph {
    font-size: 0.8rem;
    font-family: "Jost", sans-serif;
    color: #5c5571;
  }
  /* frequently asked question */
.FAQ {
  padding-left: 5% !important;
  padding-right: 5% !important;
  padding-top: 2rem;
  margin-top: 1.5rem;
}
.partner{
  padding-left: 7% !important;
  padding-right: 7% !important;
  padding-top: 3rem;
  margin-top: 1.5rem;
}
.footer{
  background-color: #263238;
  padding-top:3rem;
  padding-bottom:2rem;
  padding-left:7% !important;
  padding-right:7% !important;
}
.counter {
  background: #e8420b url(../../../assets/Counterbg.svg);
  width: 100%;
  height: auto;
  padding-left: 9% !important;
  padding-right: 9% !important;
}
.Contactus{
  margin-top:3.5rem;
  margin-bottom:2.5rem;
  background-color: #F9FAFB;
  width:80% !important;
  border-radius:0.5rem;
}


}





@media (min-width: 1000px) {
  .accbal {
    position: absolute;
    left: -16%;
    top: 12%;
  }
  .graph {
    position: absolute;
    left: 60%;
    top: 80%;
  }
  .institutionimage {
    position: absolute;
    left: 22%;
    top: 60%;
  }
  .organisationimage {
    position: absolute;
    left: 19%;
    top: 89%;
  }
  .paymentimage {
    position: absolute;
    left: 52%;
    top: 77%;
  }
  .FAQ {
  padding-left: 7% !important;
  padding-right: 7% !important;
  padding-top: 2rem;
  margin-top: 1.5rem;
}
.servicesrow{
  padding-left: 13%;
  padding-right: 13%;
  padding-top: 1rem;
  padding-bottom: 3.5rem;
}
.Contactus{
  margin-top:3.5rem;
  margin-bottom:2.5rem;
  background-color: #F9FAFB;
  width:77% !important;
  border-radius:0.5rem;
}
}



@media (min-width: 1200px) {
 
  .accbal {
    position: absolute;
    left: -5%;
    top: 12%;
  }
  .graph {
    position: absolute;
    left: 65%;
    top: 65%;
  }

  /* about adept */
  .aboutadept {
    padding-left: 8% !important;
    padding-right: 8% !important;
    padding-top: 4rem;
  }
  .aboutadeptright p {
    font-size: 0.82rem;
    line-height: 190.5%;
  }
  .aboutadeptimagecontainer {
    position: relative;
  }

  .institutionimage {
    position: absolute;
    left: 19%;
    top: 60%;
  }
  .organisationimage {
    position: absolute;
    left: 15%;
    top: 89%;
  }
  .paymentimage {
    position: absolute;
    left: 47%;
    top: 77%;
  }
  /* howitworks */

  .howitwork {
    margin-top: 7rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    background-color: #fff8f5;
  }
  .howitworkrow {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
  }
  .howitworkhead {
    color: #515151;
    font-size: 2.5rem;
    font-family: "Jost", sans-serif;
    font-weight: bold;
  }

  .howitworkparagraph {
    color: #5c5571;
    font-size: 0.85rem;
    font-family: "Jost", sans-serif;
    width: 58%;
  }

  .howitworkcardbody {
    background: #fcfcfc;
    border-top: 2px solid #f14810;
    box-shadow: 10px 8px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
    width: 100%;
  }
  .howitworkeachparagraph {
    font-size: 0.8rem;
    font-family: "Jost", sans-serif;
    color: #5c5571;
  }
  /* frequently asked question */
.FAQ {
  padding-left: 12% !important;
  padding-right: 12% !important;
  padding-top: 2rem;
  margin-top: 1.5rem;
}
.partner{
  padding-left: 8% !important;
  padding-right: 8% !important;
  padding-top: 0rem;
  margin-top: 0rem;
}
.footer{
  background-color: #263238;
  padding-top:3rem;
  padding-bottom:2rem;
  padding-left:8% !important;
  padding-right:8% !important;
}
.arrow{
  margin-left:2.5rem;
}

.counter {
  background: #e8420b url(../../../assets/Counterbg.svg);
  width: 100%;
  height: auto;
  padding-left: 9% !important;
  padding-right: 9% !important;
}
.Contactus{
  margin-top:3.5rem;
  margin-bottom:2.5rem;
  background-color: #F9FAFB;
  width:77% !important;
  border-radius:0.5rem;
}




}

@media (min-width: 1400px) {

  .accbal {
    position: absolute;
    left: 5%;
    top: 12%;
  }
  .graph {
    position: absolute;
    left: 65%;
    top: 65%;
  }
  /* about adept */
  .aboutadept {
    padding-left: 14% !important;
    padding-right: 14% !important;
    padding-top: 2rem;
  }
  .aboutadeptright p {
    font-size: 0.82rem;
    line-height: 190.5%;
  }
  .aboutadeptimagecontainer {
    position: relative;
  }

  .institutionimage {
    position: absolute;
    left: 17%;
    top: 60%;
  }
  .organisationimage {
    position: absolute;
    left: 12%;
    top: 89%;
  }
  .paymentimage {
    position: absolute;
    left: 40%;
    top: 77%;
  }
  /* howitworks */

  .howitwork {
    margin-top: 5rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    background-color: #fff8f5;
  }
  .howitworkrow {
    padding-left: 11%;
    padding-right: 11%;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
  }
  .howitworkhead {
    color: #515151;
    font-size: 2.5rem;
    font-family: "Jost", sans-serif;
    font-weight: bold;
  }

  .howitworkparagraph {
    color: #5c5571;
    font-size: 0.85rem;
    font-family: "Jost", sans-serif;
    width: 58%;
  }

  .howitworkcardbody {
    background: #fcfcfc;
    border-top: 2px solid #f14810;
    box-shadow: 10px 8px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
    width: 100%;
  }
  .howitworkeachparagraph {
    font-size: 0.8rem;
    font-family: "Jost", sans-serif;
    color: #5c5571;
  }
  /* frequently asked question */
.FAQ {
  padding-left: 20% !important;
  padding-right: 20% !important;
  padding-top: 2rem;
  margin-top: 1.5rem;
}
.partner{
  padding-left: 11% !important;
  padding-right: 11% !important;
  padding-top: 1rem;
  margin-top: 1rem;
}
.footer{
  background-color: #263238;
  padding-top:3rem;
  padding-bottom:2rem;
  padding-left:14% !important;
  padding-right:14% !important;
}
.arrow{
  margin-left:3.8rem;
}
.servicesrow{
  padding-left: 20%;
  padding-right: 20%;
  padding-top: 1rem;
  padding-bottom: 3.5rem;
}
.counter {
  background: #e8420b url(../../../assets/Counterbg.svg);
  width: 100%;
  height: auto;
  padding-left: 14% !important;
  padding-right: 14% !important;
}
.Contactus{
  margin-top:3.5rem;
  margin-bottom:2.5rem;
  background-color: #F9FAFB;
  width:62% !important;
  border-radius:0.5rem;
}



}


@media (min-width: 1600px) {

  .accbal {
    position: absolute;
    left: 5%;
    top: 12%;
  }
  .graph {
    position: absolute;
    left: 65%;
    top: 65%;
  }
  /* about adept */
  .aboutadept {
    padding-left: 20% !important;
    padding-right: 20% !important;
    padding-top: 2rem;
  }
  .aboutadeptright p {
    font-size: 0.82rem;
    line-height: 190.5%;
  }
  .aboutadeptimagecontainer {
    position: relative;
  }

  .institutionimage {
    position: absolute;
    left: 17%;
    top: 60%;
  }
  .organisationimage {
    position: absolute;
    left: 12%;
    top: 89%;
  }
  .paymentimage {
    position: absolute;
    left: 40%;
    top: 77%;
  }
  /* howitworks */

  .howitwork {
    margin-top: 5rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    background-color: #fff8f5;
  }
  .howitworkrow {
    padding-left: 11%;
    padding-right: 11%;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
  }
  .howitworkhead {
    color: #515151;
    font-size: 2.5rem;
    font-family: "Jost", sans-serif;
    font-weight: bold;
  }

  .howitworkparagraph {
    color: #5c5571;
    font-size: 0.85rem;
    font-family: "Jost", sans-serif;
    width: 58%;
  }

  .howitworkcardbody {
    background: #fcfcfc;
    border-top: 2px solid #f14810;
    box-shadow: 10px 8px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
    width: 100%;
  }
  .howitworkeachparagraph {
    font-size: 0.8rem;
    font-family: "Jost", sans-serif;
    color: #5c5571;
  }
  /* frequently asked question */
.FAQ {
  padding-left: 20% !important;
  padding-right: 20% !important;
  padding-top: 2rem;
  margin-top: 1.5rem;
}
.partner{
  padding-left: 11% !important;
  padding-right: 11% !important;
  padding-top: 0rem;
  margin-top: 0rem;
}
.footer{
  background-color: #263238;
  padding-top:3rem;
  padding-bottom:2rem;
  padding-left:20% !important;
  padding-right:20% !important;
}
.arrow{
  margin-left:3.8rem;
}
.counter {
  background: #e8420b url(../../../assets/Counterbg.svg);
  width: 100%;
  height: auto;
  padding-left: 20% !important;
  padding-right: 20% !important;
}
.Contactus{
  margin-top:3.5rem;
  margin-bottom:2.5rem;
  background-color: #F9FAFB;
  width:60% !important;
  border-radius:0.5rem;
}



}
