@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&family=Redressed&display=swap");

.herosection {
  height: auto;
  width: 100% !important;
  overflow-y: hidden !important;
  padding-bottom: 6rem;
  background: url(../../../assets/herobg.svg);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
}
.herosectionleft {
  padding-left: 3% !important;
  padding-top: 4rem;
}
.herosectionright {
  padding-top: 3rem;
}
.heroText {
  margin-top: 1rem;
  font-family: "Jost", sans-serif;
}
.heroText p {
  font-size: 0.93rem;
  width:90%;
}
.heroText h1 {
  font-size: 2.5rem;
}
.carousel-control-next-icon {
    background-image: none !important ;
   
  }
  
  .carousel-control-prev-icon {
    background-image: none !important;
  }


  /* Override the default Bootstrap carousel indicators */
.carousel-indicators{
    margin-left:0rem !important;
    justify-content: center !important;
    margin-bottom: -2rem !important;
    margin-top: 55px !important;
    margin-right:0% !important;

}
.carousel-indicators button {
    background: url(../../../assets/carouselnonactivebg.svg) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    border: 2px solid #F14810 !important;
    border-radius: 50% !important;
    width: 12px !important;
    height: 12px !important;
    padding: 1.1px !important; /* Add padding to create a separation between border and background */
    margin: 0 4px !important;
    transition: background-color 0.3s ease;
  }
  
  /* Active indicator style */
  .carousel-indicators .active {
    background: url(../../../assets/carouselactivebg.svg) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }

 





  .circleradiusdiv {
    border-radius: 29px;
    background: #ffffff;
    display: flex;
    padding:4px !important;
    justify-content: center;
    align-items: center;
    width: 9rem !important;
    margin-bottom: 0.5rem;
    font-size: 0.8rem;
    font-family: "Jost", sans-serif;
    color: #515151 !important;
    font-weight: 600;

    gap:0.3rem;
  }

  




@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .herosectionleft {
    /* background: url(../../assets/herobg.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain; */
    padding-left: 7% !important;
    padding-top: 2rem;
  }
  .heroText {
    margin-top: 4rem;
    font-family: "Jost", sans-serif;
  }
  .heroText p {
    font-size: 0.84rem;
    width:90%;
  }
  .heroText h1 {
    font-size: 2.4rem;
  }
  
}







@media (min-width: 820px) {
  .accbal {
    position: absolute;
    left: -13%;
    top: 12%;
    display: inline;
  }
  .graph {
    position: absolute;
    left: 52%;
    top: 80%;
    display: inline;
  }
}





@media (min-width: 992px) {
  .herosectionleft {
    /* background: url(../../assets/herobg.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain; */
    padding-left: 7% !important;
    padding-top: 2rem;
  }
  .accbal {
    position: absolute;
    left: -17%;
    top: 12%;
  }
  .graph {
    position: absolute;
    left: 60%;
    top: 80%;
  }

  /* about adept */
 
}





@media (min-width: 1000px) {
  
}

@media (min-width: 1200px) {
  .herosectionleft {
    /* background: url(../../assets/herobg.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain; */
    padding-left: 8% !important;
  }
  
  /* about adept */
  .aboutadept {
    padding-left: 8% !important;
    padding-right: 8% !important;
    padding-top: 8rem;
  }
  .aboutadeptright p {
    font-size: 0.82rem;
    line-height: 190.5%;
  }
  .aboutadeptimagecontainer {
    position: relative;
  }

  .institutionimage {
    position: absolute;
    left: 19%;
    top: 60%;
  }
  .organisationimage {
    position: absolute;
    left: 15%;
    top: 89%;
  }
  .paymentimage {
    position: absolute;
    left: 47%;
    top: 77%;
  }
  /* howitworks */

  .howitwork {
    margin-top: 7rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
    background-color: #fff8f5;
  }
  .howitworkrow {
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 1rem;
    padding-bottom: 1.2rem;
  }
  .howitworkhead {
    color: #515151;
    font-size: 2.5rem;
    font-family: "Jost", sans-serif;
    font-weight: bold;
  }

  .howitworkparagraph {
    color: #5c5571;
    font-size: 0.85rem;
    font-family: "Jost", sans-serif;
    width: 58%;
  }

  .howitworkcardbody {
    background: #fcfcfc;
    border-top: 2px solid #f14810;
    box-shadow: 10px 8px 10px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 2rem;
    width: 100%;
  }
  .howitworkeachparagraph {
    font-size: 0.8rem;
    font-family: "Jost", sans-serif;
    color: #5c5571;
  }
  /* frequently asked question */
.FAQ {
  padding-left: 8% !important;
  padding-right: 8% !important;
  padding-top: 4rem;
  margin-top: 3rem;
}
.partner{
  padding-left: 8% !important;
  padding-right: 8% !important;
  padding-top: 6rem;
  margin-top: 3rem;
}
.footer{
  background-color: #263238;
  padding-top:3rem;
  padding-bottom:2rem;
  padding-left:8%;
  padding-right:8%;
}
.arrow{
  margin-left:2.5rem;
}




}

@media (min-width: 1400px) {
  .herosectionleft {
    /* background: url(../../assets/herobg.svg);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: contain; */
    padding-left: 14% !important;
  }
  .accbal {
    position: absolute;
    left: 5%;
    top: 12%;
  }
  .graph {
    position: absolute;
    left: 65%;
    top: 65%;
  }
  /* about adept */
 

}

@media (min-width: 1600px) {
  .herosectionleft {
   
    padding-left: 20% !important;
  }
}
