.section{
    background:url(../../assets/Kenyamap.svg) #FFF9F7 ;
    height:100dvh;
    background-repeat:no-repeat;
    background-size: contain;
    background-position: center;
    padding-left: 0.4%;
    padding-right:0.4%;
    padding-top:30vh;
}
.herotext{
    width:100%;
}
.heroheader{
    font-size: 2.4rem;
}





@media (min-width: 576px) { 
    .section{
        background:url(../../assets/Kenyamap.svg) #FFF9F7 ;
        height:100dvh;
        background-repeat:no-repeat;
        background-size: contain;
        background-position: center;
        padding-left: 3%;
        padding-right:3%;
        padding-top:30vh;
    }
 }


@media (min-width: 768px) {
    .section{
        background:url(../../assets/Kenyamap.svg) #FFF9F7 ;
        height:100dvh;
        background-repeat:no-repeat;
        background-size: contain;
        background-position: center;
        padding-left: 7%;
        padding-right:7%;
        padding-top:30vh;
    }
    .herotext{
        width:45%;
    }
    .heroheader{
        font-size: 3rem;
    }
    
  }


@media (min-width: 992px) {  }


@media (min-width: 1200px) {
    .section{
        background:url(../../assets/Kenyamap.svg) #FFF9F7 ;
        height:100dvh;
        background-repeat:no-repeat;
        background-size: contain;
        background-position: center;
        padding-left: 9% !important;
        padding-right:9% !important;
        padding-top:30vh;
    }
  }


@media (min-width: 1400px) { 
    .section{
        background:url(../../assets/Kenyamap.svg) #FFF9F7 ;
        height:100dvh;
        background-repeat:no-repeat;
        background-size: contain;
        background-position: center;
        padding-left: 14% !important;
        padding-right:14% !important;
        padding-top:30vh;
    }
 }
 @media (min-width: 1600px) { 
    .section{
        background:url(../../assets/Kenyamap.svg) #FFF9F7 ;
        height:100dvh;
        background-repeat:no-repeat;
        background-size: contain;
        background-position: center;
        padding-left: 20% !important;
        padding-right:20% !important;
        padding-top:30vh;
    }
 }